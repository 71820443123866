.av-loading-skeleton{
    margin: auto;
    width: 100%;
    height: 100%;
    margin-bottom: 5rem;
    max-width: 550px;
    padding: 1rem 2rem;
}

.grid-layout{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column: 1 / 3;
    gap: 1rem;
}

.horizontal-cards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    max-width: 100%;

}

.horizontal-cards .loading-container:nth-child(5),
.horizontal-cards .loading-container:nth-child(6),
.horizontal-cards .loading-container:nth-child(7),
.horizontal-cards .loading-container:nth-child(8),
.horizontal-cards .loading-container:nth-child(9),
.horizontal-cards .loading-container:nth-child(10){
display: none;
}

.av-loading-skeleton-side{
    display: flex;
}

.av-loading-skeleton-side .loading-container:nth-child(1),
.av-loading-skeleton-side .loading-container:nth-child(2),
.av-loading-skeleton-side .loading-container:nth-child(3),
.av-loading-skeleton-side .loading-container:nth-child(4),
.av-loading-skeleton-side .loading-container:nth-child(5),
.av-loading-skeleton-side .loading-container:nth-child(6){
    display: none;
}


.av-loading-skeleton .loading-container div:nth-child(1){
    margin-bottom: 1.1rem;
     width: 100%;
     height: 150px;
     animation : skeleton 1s linear infinite alternate;
}

.av-loading-skeleton .loading-text{
    margin-top: .5rem;
    width: 100%;
    height: 8px;
    animation : skeleton .6s linear infinite alternate;
    border-radius: 5px;
}

.av-loading-skeleton .loading-text:last-child{
    width: 80%;
}

.loading-page {
    display: block;
}

.loading-page .loading-container{
    width: 100%;
    background: transparent;
}

.av-loading-skeleton .loading-container div:nth-child(1).loading-photo{

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    background: transparent !important;
    height: 450px;
    margin-top: 4rem;

} 

.loading-page .loading-container .loading-photo .loading-text{
    width: 100%;
    height: 100%;
    margin: 0;
}

.loading-page .loading-container .loading-photo .loading-text:nth-child(1){
    grid-row: 1 / 3;
}

.loading-content-container{
    display: grid;
     grid-template-columns: 1fr .5fr;
     margin: 3rem 0rem;
     margin-bottom: 0;
     gap: 2rem;
     height: 350px;
}

.loading-content{
     display: flex;
     flex-flow: column;
     background: white !important;
}

.loading-content .loading-prop-info{
    background: white !important;
    height: max-content !important;
    display: flex;
    flex-flow: column;
    gap: 1rem;
    margin-bottom: 3rem !important;
}

.loading-content .loading-prop-info .loading-text{
    height: 10px !important;
    margin: 0 !important;
}

.loading-agent-content{
    background: white !important;
}

.loading-agent-content .loading-text:nth-child(1){
    border-radius:5px;
    height: 150px !important;
    margin: auto !important;
    margin-bottom: 2rem !important;
}

.loading-agent-content .loading-text{
    height: 10px;
    margin-bottom: 1rem;
}

.loading-similar-listing{
    display: flex;
    gap: 3rem;
    margin-top: 3rem;
}



@keyframes skeleton {

    0%{
        background: #f8f8f88c;
    }
    100%{
        background: #f4f4f4;
    }
    
}