.home_nav{
    background: transparent !important;
    border-bottom: none !important;
    padding: 1rem 0rem !important;
    position: absolute;
    width: 100%;
}

.hero{
    background: #daeff7;
    position: relative;
}

.hero article{
    position: relative;
    z-index: 1;
}

.hero img{
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 600px;

}


.feature-list{
    margin-bottom: 1rem;
}

.feature-list li{
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: .4rem;

}

.feature-list li h4{
    display: none;
 
}

.s-header{
    color: var(--secondary-color);
    text-transform: uppercase;
    font-weight: 600;
    font-size: .7rem;
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
}

.s-header div{
    height: 1px;
    background: #0d294d73;
    width: 25px;
    margin-right: .8rem;
    display: none;
}

.feature-list i{
    color: var(--main-color);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    font-weight: bold;
    font-size: 1.2rem;
}

.feature-list p{
    grid-column: 2 / 3;
}

.photo-grid{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: .5rem;
      max-height: 570px;
      max-width: 580px;
}

.content-area{
    max-width: 550px;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.photo-grid figure:nth-child(1){
    grid-row: 1 / 3;
}

.photo-grid figure{
    border-radius: 5px;
}

.photo-column{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: .5rem;
    max-width: 580px;
    height: 450px;
}

.photo-column figure{
    position: relative;
    border-radius: 5px;
}

.photo-column figure:first-child{
    bottom: 25px;
}

.photo-column figure:last-child{
    top: 25px;
}


.quick-search{
    background: white;
    max-width: 772.8px;
    border-radius: var(--borderRadius);
    display: flex;
    padding: 1.5rem 3rem;
    align-items: flex-end;
    gap: 1rem;
    box-shadow: 5px 10px 20px -13px #a5c9da;
    margin-top: 2rem;
}

.quick-search ul{
    display: flex;
    gap: 1rem;
  
}

.quick-search ul li h3{
    margin-bottom: .5rem;
font-weight: 600;
color: var(--alt-text-color);
font-size: .9rem;
}

.quick-search ul li .input-container{
    position: relative;
}

.quick-search ul li .input-container i{
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.quick-search ul li input{
    border: none;
    background: var(--alt-silver);
    padding: .7rem .88rem;
    width: 100%;
    border-radius: var(--borderRadius);
}

.quick-search ul li:nth-child(2){
    max-width: 150px;
}

.quick-search ul li:nth-child(3){
    max-width: 190px;
}

.q-dropdown{
    background: white;
    position: absolute;
    left: 0;
    top: 120%;
    border-radius: 15px;
    box-shadow: 0px -2px 50px -20px #8e9da496;
    border: 1px solid var(--light-silver);
   z-index: 2;
   width: 200px;
   max-width: 350px;
}

.q-dropdown h3{
    padding: .5rem 1.5rem;
    background: #f5f7f9;
    font-size: .7rem !important;
    color: var(--alt-text-color);
}

.q-dropdown .btn-container{
    padding: .5rem 1.5rem;
}

.q-dropdown button{
    width: 100%;
}

.q-dropdown ul{

    flex-flow: column;
    gap: 0rem;

}

.q-dropdown ul li{
    padding: .5rem 1.5rem;
    cursor: pointer;
    font-size: .9rem;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    max-width: 100% !important;
    transition: background .2s;
}

.q-dropdown ul li:hover{
    background: var(--alt-silver);
}

.q-dropdown ul li i{
    position: relative !important;
    margin-left: 0rem !important;
    margin-right: .5rem !important;
    color: var(--alt-text-color);
    right: 0px !important;
}

.range-option{
    display: flex;
    width: max-content;
    padding: .5rem 1.5rem;
    gap: 1rem;
    max-width: 100%;
}

.range-option h3{
    background: white !important;
    padding: 0rem;
}

.range-option .input-container input{
    width: 100%;
    border: 1px solid var(--light-silver);
    background: white;
    cursor: pointer;

}

.q-dropdown.range-dropdown{
    overflow: visible !important;
    width: 300px;
}

.q-dropdown .price-list{
    width: 100%;
    max-height: 300px;
    overflow:visible;
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow-y: scroll;
}

footer{
    background:#071537;
    padding: 1rem 0rem;
    padding-top: 2rem;
}

footer h2{
    font-size: 3rem;
}

footer h2, footer i{
    color: white;
}

.copy_writes{
    border-top: 1px solid #ffffff26;
    text-align: center;
    margin-top: 1rem !important;
    padding-top: 1rem;
    display: flex !important;
    justify-content: center !important;
}

.copy_writes p{
    color: #ffffff91;
    text-align: center;
    width: 100%;
    font-size: .8rem;
}

.contact-info-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.contact-info-footer li{
    display: flex;
    align-items: center;
    gap: .5rem;
}

.contact-info-footer li span{
    color: white;
    font-size: .8rem;
}

.footer_main ul {
    display: flex;
    gap: 1.5rem;
}

.footer_main ul li{
    color: white;
    font-size: .8rem;
}

.side-bar-window{
    background: white;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 400px;
    min-width: 300px;
    z-index: 101;
    padding: .5rem 0rem;
}


.side-bar-list{
 display: flex;
 flex-flow: column;
 gap: 1.5rem;
 padding: 2rem 1rem;
}

.side-bar-list li{
    font-size: 18px;
    font-weight: 500;
}

.side-bar header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
    padding-top: .5rem ;
}

.side-bar-close-btn{
   font-size: 2rem;
   font-weight: 100;
   border-radius: 50%;
   transform: rotate(45deg);
   cursor: pointer;
}

.nav-user-option{
    display: flex;
    gap: .5rem;
    border-radius: var(--borderRadius);
    border: 1px solid var(--alt-silver);
    background: var(--alt-silver);
    padding: .3rem;
}

.nav-user-option button{
    padding: .7rem 1.3rem;
    width: max-content;
}

.message-service{
  position: relative;
  background: white;
  z-index: 50;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  width: 90%;
  border-radius: 8px;
  padding: 1rem;
  top: 20px;
  box-shadow: 0px 0px 65px -6px #444b5991;
  border: 1px solid var(--light-silver);
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.message-service i{
    margin-top: 5px;
    font-size: 2rem;
}

.message-service h2{
    font-size: 20px;
    margin-bottom: .3rem;
}

.message-service p{
    max-width: max-content;
}

.info-message{
    border-left: 4px solid #269fe8;
}

.info-message i{
    color: #269fe8;
}

.info-error{
    border-left: 4px solid rgb(231, 73, 73);
}

.info-error i{
    color: rgb(231, 73, 73);
}

.message-close-btn{
    position: absolute;
    top: 0;
    right: 15px;
    font-size: 2.5rem;
    font-weight: 100;
    border-radius: 50%;
    transform: rotate(45deg);
    cursor: pointer;
}

.listing-footer .footer_main{
    flex-flow: column;
    gap: 1rem;
}