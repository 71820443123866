

.carousel{
    width: 100%;
    position: relative;
}

.carousel_container{
    white-space: nowrap;
    overflow: hidden;
    scroll-behavior: smooth;
}



.carousel_container .item:first-child{
    margin-left: 0;
}

.carousel_container .item:nth-last-child(){
    margin-right: 0;
}



.carousel_btn{
    border: 1px solid var(--borderColor);
    color: #343f4f;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;

}


.next_btn{
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    /* bottom: 0;
    margin: auto; */
}

.prev_btn{
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    /* bottom: 0;
    margin: auto; */

}

.carousel_container .item{
    
    position: relative;
    display: inline-block;

}

.carousel_container .item{
    padding-left: 10px;
}


.item > div{

    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: max-content !important;
}


.split_4  .item{

    padding-left: 5px;

}

.split_4  .item:first-child{
    padding-left: 0px;
}


.split_2  .item{
    width: calc(100% / 3);
    padding-left: 6px;
}
.split_2  .item:first-child{
    
    padding-left: 0px;

}

.pagination{
    width: 300px;
    
    margin: 1rem 0rem;
    margin-left: 1rem;

}

.pagination .carousel_btn{
    border: none;
    width: 20px;
    height: 20px;
    font-size: .9rem;
}

.pagination .item{
    padding-left: 20px;
}


.carousel_container > .item:first-child{
    padding-left: 0px;
}


@media (max-width: 800px) {

    .carousel .prev_btn, .carousel .next_btn{
        display: none !important;
    }

    .carousel .carousel_container{
        overflow-x: scroll;
    }
}
