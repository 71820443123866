form {
background: white;
border-radius: 5px;
}

form h2{
    margin-bottom: 2rem;
}

form label{
    color: #212c51;
    margin-bottom: 8px;
    display: block;
}

form fieldset{
    margin-bottom: 1.5rem;
}

form fieldset input{
        border-radius: var(--borderRadius);
        padding: .7rem 1rem;
        border: 1px solid #70879f78;
        width: 100%;
        height: 50px;
}

form fieldset select{
    border-radius: var(--borderRadius);
    padding: .7rem 1rem;
    border: 1px solid #70879f78;
    width: 100%;
    height: 40px;
}

form button{
    height: 50px;
}

.field_error input, .field_error textarea{
    border: 1px solid red;
    background: #ff000008;
}

.field_error .error-message{
    color: rgb(237, 80, 80) !important;
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 12px;
}

.field_error p{
    color: rgb(237, 80, 80) !important;
}

form textarea{
    border-radius: var(--borderRadius);
    border: 1px solid #586b8459;
    width: 100%;
    height: 80px;
    padding: .5rem;
}

.main-form{
    width: 100%;
    max-width: 420px;
    width: 350px;
}

.login-form h3{
    font-size: 2rem;
    margin-bottom: .5rem;
    font-weight: 700;
}

.login-form label, .login-form a{
    font-weight: 500;
    font-size: 15px;
    color: var(--dark-text-color)
}

.login-form a{
    cursor: pointer;
}
.login-form fieldset{

    margin-bottom: 1rem;
}

.login-form fieldset p{
    margin-bottom: .2rem;
    color: var(--dark-text-color);
}

.login-form fieldset input{
    grid-row: 2;
    grid-column: 1 / 3;
}

.login-form fieldset input::placeholder {
    color: #97a1ae;
    font-weight: 400;
}

.login-form button{
    margin-top: .5rem;
}

.signup-link{
    color: var(--secondary-color) !important;
    text-decoration: underline;
}

.form-descr{
    margin-bottom: 1.3rem;
    color: #97a1ae;
    max-width: 550px;
    line-height: 1.8;
    font-size: 16px;
}

.login-form .forgot-password{
    font-size: 13px;
}

.login-form .form-option{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.remember-me{
    display: flex;
    align-items: center;
    gap: .5rem;
}

.remember-me p{
    margin: 0;
    font-size: 14px;
    color: #97a1ae;
}
/* .form_error fieldset p{
    color: red;
    margin-top: 10px;
} */