.show-properties{
    display: flex;
    flex-flow: column;
    gap: .4rem;
    padding: 0rem .5rem;
    margin-top: 1rem;
    height: max-content;
}
.properties-section{
    overflow: hidden;
    overflow-y: hidden;
    height: calc(100vh - 151px);
    overflow-y: scroll;
}

.show-properties-header{
    padding: 1rem;
    padding-bottom: 0;
}

.show-properties-header h5{
    font-size: 1rem;
}

.show-properties-header h2{
    font-weight: 500;
}

.property_list_container{
    padding-bottom: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-flow: wrap;
    gap: .5rem;
    justify-content: center;
    padding: 1rem;
    max-width: 700px;
}


.property-card{
    max-width: 340px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #d6dde4;
    cursor: pointer;
    max-height: 360px;
}

.property-card figure{
    position: relative;
    width: 100%;
    height: 160px;
    max-height: 232px;
    overflow: hidden;
    
}

.property-card .prop-info{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 1rem;
    row-gap: .4rem;
    /* font-size: .9rem; */
}

.property-card .prop-info i{
    grid-column: 3;
    align-self: center;
    justify-self: flex-end;
    font-size: 1.4rem;
    border-radius: 50%;
    transition: all .2s;
}

.property-card .prop-info i.fa-heart{
  border-radius: 50%;
  transition: all .2s;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prop-beds i, .prop-baths i, .prop-sqft i{

        font-size: 12px !important;
        margin-right: 0rem;
        color: var(--alt-text-color);
        /* background: #e9f0f5ab !important; */
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
}

.property-card .prop-info span{
    color: var(--alt-text-color);
    font-weight: 500;
    margin-left: 3px;
}

.property-card .prop-price{
    font-weight: 600;
    font-size: 1.25rem;
    position: relative;
    grid-column: 1 / 3;
    align-self: center;
}

.property-card .prop-price span{
    font-size: .9rem;
    font-weight: 600;
}

.prop-beds, .prop-baths, .prop-sqft, .prop-address{
    display: flex;
    align-items: center;
    font-size: 16px;
}

.property-card .prop-info i:hover{
    background: var(--light-color);
}

.property-card .prop-address{
    position: relative;
    grid-column: 1 / 4;
    font-weight: 500;
    line-height: 1.5;
    /* font-size: .9rem; */
}

.property-card .prop-address p{
  color: var(--dark-text-color);
  line-height: 1.5;
}




.property-card img{
    position: absolute;
    width: auto;
    width: 100%;
}

.property-card .status-container{
    position: absolute;
    top: 5px;
    left: 5px;
    display: flex;
    gap: .4rem;
}

.status-component{
    padding: .3rem .4rem;
    font-weight: 600;
    font-size: 9px;
    width: max-content;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}

.status-component div{
    border-radius: 100px;
    padding: .3rem .5rem;
    font-weight: bold;
    color: white;
}

.info-window-prop-card{
    border: none;
    max-width: 200px;
}

.info-window-prop-card figure{
    max-height: 100px;
}

.info-window-prop-card .prop-info{
    padding: 0rem;
    gap: .2rem;
}

.like-prop{
    color: var(--main-color) !important;
}
.status-for-sale{
    background: #479d47;
}

.status-for-rent{
    background: #3737ca;
}

.status-sold{
    background: #ff4141;
}

.status-ready-to-build{
    background: #ff9231;
}

.status-off-market{
    background: purple;
}

.status-new-community{
    background: lightblue;
}

.type-apartment{
    background: #479d47;
}

.type-townhomes{
    background: #3777ca;
}

.type-condos{
    background: #ff4141;
}

.type-duplex_triplex{
    background: #ff9231;
}

.type-farm{
    background: purple;
}

.type-mobile{
    background: #b5881f;
}

.type-single_family{
    background: #8b22d3;
}

.type-multi_family{
    background: #b534ad;
}

.type-coop{
    background: #2290d3;
}

.like_style{
    color: #ff4141;
}


@media (max-width: 1257px) {

    .property_list_container .property-card{
        width: 100%;
    }
    
}



.single_prop_header{
    display: flex;
    padding: 1.3rem 0rem;
    align-items: center;
    justify-content: space-between;
    background: white;
    position: sticky;
    top: 0;
    z-index: 20;
}



.single_prop_header ul {
    display: flex;
    gap: 1rem;
}


.single_prop_header ul li{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.single_prop_header .list_options{
    gap: 2rem;
}


.s-h-btn i{
    font-size: .9rem;
    color: var(--alt-text-color);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.single_back_btn{
    background: transparent;
    border: none;
}

.single_back_btn{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: var(--secondary-color);
    font-weight: 500;
}

.single_back_btn i {
    color: var(--secondary-color);
}


.list_options li{
    padding: .6rem 0rem;
    transition: background .3s;
}


.list_options i{
    color: var(--alt-text-color);
}

.active_list{
    /* border-bottom: 2px solid var(--main-color); */
    position: relative;
}

.active_list::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: var(--main-color);
    bottom: 0;
}

.prop_description p{
    max-width: 750px;
}

.prop_info_container{

    display: grid;
    grid-template-columns: 1fr .4fr;
    column-gap: 3rem;
    margin: 3rem 0rem;

}

.prop_info h2{
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 1.5rem;
}

.prop_info h3{
    margin-bottom: .2rem;
}

.prop_info_header{
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
}

.prop_info_header figure{
    background: var(--light-silver);
    border-radius: 10px;
}

.prop_info_header .mini-map{
    max-width: 380px;
    height: 250px;
    width: 100%;
    border-radius: 5px;
    position: relative;
    display: grid;
    grid-template-rows: 1fr auto;
    box-shadow: 0px 5px 35px 16px #c9cfd936;
    overflow: hidden;
}

.prop_info_header .mini-map figure{
    overflow: hidden;
    border-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.prop_info_header .mini-map figure img{
    width: 100%;
    height: 100%;
}

.listing-layout{
    display: flex;
    margin-top: 71.2px;
}

.commute{
    display: flex;
    padding: 1rem;
    background: white;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    /* background: var(--lightSilver); */
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.commute h3{
    margin: 0;
    padding-left: .3rem;
    margin-right: .5rem;
    border-right: 1px solid var(--light-silver);
    padding-right: 1rem;
}

.commute i{
    font-size: 1.2rem;
    /* padding-right: 1rem; */
    
}

.commute p{
    padding-left: .5rem;
    color: var(--secondary-color);
    margin-bottom: 0rem;
}

.prop_header_details{
    display: flex;
    flex-flow: column;
    gap: .4rem;
}

.prop_header_details h2{
    padding: 0;
    margin: 0;
    background: white;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.prop_status{
    display: flex;
    gap: .6rem;
    color: var(--alt-text-color);
    margin-bottom: .7rem;
    align-items: center;
}

.prop_status_marker{
    content: "";
    width: 15px;
    height: 15px;
    display: block;
    position: relative;
    border-radius: 5px;
}


.prop_header_details h3{
    margin: 0;
    font-size: 1.8rem;
    font-weight: 600;
}

.prop_header_details h3 span{
    color: var(--secondary-color);
    font-size: 1rem;
    margin-left: .6rem;
    font-weight: 500;
}

.prop_header_details h4{
    margin: 0;
    font-weight: 500;
    color: var(--alt-text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .3rem;
}

.prop_header_details h4 span{
    font-weight: 600;
    color: var(--dark-text-color);
}


.prop_info_list{
    display: flex;
    flex-wrap: wrap;
    margin-top: .6rem;
}

.prop_info_list i{
    color: var(--alt-text-color);
    background: var(--lightSilver) !important;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .6rem;
    margin-right: .4rem;
    border: 1px solid var(--light-silver);
}

.prop_info_list li{
    display: flex;
    text-align: center;
    padding: 0rem 1rem;
      padding-left: 1rem;
    border-right: 1px dotted #082b59;
    justify-content: center;
    align-content: center;
}

.prop_info_list li:nth-child(1){
    padding-left: 0;
}

.prop_info_list li:nth-last-child(1){
    padding-right: 0;
    border-right: none;
}



.prop_info_list h3{
    font-weight: 500;
}

.prop_info_list p{
    font-weight: 600;
    
}

.status-container{
    display: flex;
    gap: .5rem;
    margin-bottom: 1rem;
}
.prop_details{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: .6rem;
    column-gap: 3rem;
}

/* .prop_details h3, .prop_details p{
    display: inline;
} */

.status-type{
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    padding: .6rem 1rem;
    font-size: .8rem;
    border-radius: 100px;
}

.prop_details .expand-btn{
    padding: 0rem 1rem;
}
.button_container{
    display: flex;
    gap: 1.5rem;
}
.offer_button{
    margin-top: 1.3rem;
    /* height: 50px; */
    max-width: max-content;
}


.school_option{
  width: max-content;
  text-align: left;
  width: 100%;
  /* min-width: 600px; */
}

.school_option tr:nth-child(1){
    border-bottom: 1px solid black;
    padding: 1rem;
}

.school_option tr th{
    padding: 1rem 0rem;
    font-weight: 600;
}

.school_option tr td{
    border-bottom: 1px solid var(--light-silver);
    padding: 1rem 0rem;
    color: var(--alt-text-color);
}

.school_option tr b{
    font-weight: 600;
    color: var(--alt-text-color);
}

.property_history_container{
    margin-top: 1rem;

}

.property_history{
    padding-left: 2rem;
    padding-bottom: 2rem;
    position: relative;
    margin-left: .4rem;
}

.property_history::before{
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    left: -6px;
    top: 0;
    background-color: #cfd9e6;
}

.property_history::after{
    content: "";
    position: absolute;
    height: 90%;
    width: 1px;
    background-color: #cfd9e6;
    left: 0;
    bottom: 0;



}

.property_history ul{

    margin-left: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    max-width: 600px;

}

.property_history ul li h4{

    font-weight: 500;

}

.table_style table th, .table_style table td{
    padding-right: 1rem;
  }

  .photo-gallery{
    width: 90vw;
    overflow-y: scroll;
    height: max-content;
    max-height: 90vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: .5rem;
    padding: 2rem;
}

.photo-gallery figure{
    width: 100%;
    padding-bottom: 75%; /* 16:9 aspect ratio (height / width * 100) */
    position: relative;
    overflow: hidden;
}

.more_details{
    position: absolute;
    z-index: 1;
    top: 1rem;
    left: 1rem;
    display: flex;
    gap: 1rem;
}

.more_details li{
    background: white;
    border-radius: var(--borderRadius);
    padding: .5rem 1rem;
    font-weight: 600;
    transition: background .3s;
    cursor: pointer;
    font-size: .9rem;
}

.more_details li:hover{
    background: rgb(240, 240, 240);
}


.prop_photos{
    height: 500px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr .5fr;
    gap: .3rem;
    cursor: pointer;
}

.prop_photos figure{
    height: 100%;
    border-radius: 10px;
}

.prop_photos figure:nth-child(1){
    grid-row: 1 / 3;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.prop_photos figure:nth-child(2),
.prop_photos figure:nth-child(3) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.prop_photos figure:nth-child(2){
    border-bottom-right-radius: 0px;
}

.prop_photos figure:nth-child(3){
    border-top-right-radius: 0px;
}

.prop_photos_1{
    height: 500px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    cursor: pointer;
}

.prop_photos_1 figure{
    height: 100%;
    border-radius: 10px;
}

.prop_photos_2{
    height: 500px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    cursor: pointer;
    gap: .3rem;
}

.prop_photos_2 figure{
    height: 100%;
    border-radius: 10px;
}

.schedule{
    position: sticky;
height: max-content;
top: 100px;
max-width: 300px;
}

.schedule_title {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
}

.schedule_title span{
  color: var(--alt-text-color);
  font-weight: 500;
  font-size: 1rem;
  display: block;
  margin-top: .5rem;
}
.schedule-tour .slider-btn-right{
    right: -22px
}

.schedule-tour .slider-btn-left{
    left: -22px
}


.tour_type{
    display: flex;
    column-gap: .5rem;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.tour_type li{
width: 100%;
padding: 1rem;
border: 1px solid var(--borderColor);
border-radius: 100px;
display: flex;
justify-content: center;
align-items: center;
gap: .5rem;

}

.tour_type li i{
    color: var(--alt-text-color);
}




.select_time {width: 100%;
height: 50px;
background: white;
border: 1px solid var(--borderColor);
border-radius: 5px;
padding: 0rem 1rem;
cursor: pointer;
margin-top: 1rem;
margin-bottom: 1rem;
}

.time-option button{
    background: white;
    border: 1px solid var(--borderColor);
    padding: 1rem 1rem;
    margin: 1rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.time-option .drop-down-window{
  position: absolute;
  left: 0;
  top: 110%;
  border-radius: 10px;
  box-shadow: var(--shadow);
  width: 100%;
  background: white;
  border: 1px solid rgb(228, 227, 227);
  z-index: 1;
  min-width: 230px;
  padding:  1rem;
  padding-bottom: 0rem;

}

.time-option .drop-down-window ul li{
    cursor: pointer;
    margin-bottom: .8rem;
}

.schedule-time{
    display: flex;
    flex-flow: column;
    gap: .1rem;
    text-align: center;
    border: 1px solid var(--borderColor);
    border-radius: 10px;
    padding: .6rem 1rem;
    cursor: pointer;
    transition: background .2s;
}

.schedule-time h2{
    font-size: 1.3rem;
    margin: 0;
}

.schedule-time h3{
    font-weight: 500;
    font-size: .8rem;
}

.schedule-time:hover{
    background: #3d98c421;
}

.active-time{
    background: #3d98c421 !important;
    border: none !important;
    border: 2px solid var(--secondary-color) !important;
}

.schedule-tour .slider-btn-right{
    right: -22px
}

.schedule-tour .slider-btn-left{
    left: -22px
}

.ask_question_form{
    min-width: 100%;
}

.ask_question_form textarea{
    border-radius: 5px;
    border: 1px solid var(--borderColor);
    width: 100%;
    height: 80px;
    padding: .5rem;
    margin-top: 1rem;

}

.signup_form{
    width: 500px;
}


.agent_info h2{

    margin-bottom: 2rem !important;
}
.personal_info{
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content max-content max-content;
    column-gap: 2rem;
    row-gap: .5rem;
    margin-bottom: 1.4rem;

}

.agent-head-shot{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: var(--light-color);
    margin: auto;
    overflow: hidden;
    grid-row: 1 / 4;
}

.agent-head-shot img{
    width: 100%;
}

.contact-info{
    display: flex;
    gap: .5rem;
    margin-top: 1rem;
}

.contact-info li{
    background: var(--lightSilver);
    padding: .6rem 1rem;
    border-radius: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}

.contact-info li i{
    font-size: 1rem;
    color: var(--alt-text-color);
}

.contact-info li p{
    font-size: .8rem;
    margin: 0;
}

.personal_info h4{
    font-size: 1.4rem;
    display: block;
    color: var(--secondary-color);
}

.personal_info h5{
    font-size: 1rem;
}

.agent form{
    /* margin: 2rem 0rem; */
    min-width: 100%;
    display: flex;
    flex-flow: column;
}

.agent form h2{
    margin-bottom: 1rem;
}

.agent form fieldset{
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 100%;
}

.agent form fieldset input{
    width: 100%;
    height: 45px;
    border-radius: 10px;
    border: 1px solid #586b8459;
    padding-left: .5rem;
}

.agent form fieldset textarea{
    width: 100%;
    height: 175px;
    border-radius: 10px;
    border: 1px solid var(--borderColor);
    padding: .5rem;
}

.agent form button{
    width: max-content !important;
}


.agent ul li{
    margin-bottom: 1.5rem;
}

.prop_info{
    display: flex;
    flex-flow: column; 
    gap: 3rem;
}

.agent ul li p{
    margin-bottom: .5rem;
}


.show_button{
    cursor: pointer;
}

.show_button{
    cursor: pointer;
    margin-top: 1rem;
    font-weight: 500;
    width: max-content;
    border-radius: 100px;
    font-size: .9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .3rem;
    
}

.show_button span{
    color: var(--secondary-color) 
}

.show_button i{
    color: var(--secondary-color);
}

