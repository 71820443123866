/* 
=================================================
MODAL
=================================================
*/

.modal-bk{
    background: #141a1cd1;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}
.modal-window{
   z-index: 101;
   max-width: max-content;
   width: max-content;
   height: max-content;
   background: white;
   position: fixed;
   top: 50px;
   left: 50%;
   transform: translateX(-50%);
   padding: 2.7rem 2rem 2rem;
   bottom: 0;
   border-radius: 5px;
}

.close-btn{
   position: absolute;
   font-size: 2rem;
   font-weight: 100;
   width: 30px;
   height: 30px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   z-index: 2;
   right: 10px;
   top: 10px;
   transform: rotate(45deg);
   cursor: pointer;
}
