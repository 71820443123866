
@media (max-width: 1643px) {
    .search-result{
        grid-template-columns: 1fr .8fr;
    }
}

@media (max-width: 1385px) {

   .container-medium{
       width: 93%;
   }

   .search-result{
    grid-template-columns: 1fr 1fr;
}
    
}

@media (max-width: 1337px) {

    .single_prop_header ul.list_options{
        display: none;
    }
    
}

@media (max-width: 1250px) {

    .search-result{
        grid-template-columns: 1fr .4fr;
    }

    .show-properties{
        grid-template-columns: 1fr !important;

    }

    .show-properties-header{
        grid-column: 1 !important;
    }

    .nav-contact-info{
        display: none;
    }
    .property_filter .home_baths{
        display: none;
    }
    
    .property_filter .home_beds{
        display: none;
    }
}

@media (max-width: 1211px) {


    .prop_info_header{
        flex-flow: column;
    }


    .prop_info_header .mini-map{
        margin-top: 2rem;
        max-width: 100%;
        height: 250px;
    }

    .tour_type {
        flex-flow: column;
    }

    .tour_type li:nth-child(2){
        margin-top: 1rem;
    }

    .contact-info{
        flex-flow: column;
    }

    .footer_main{
        flex-flow: column;
        gap: 1rem;
    }
    
}

@media (max-width: 1163px){


    .search-result{
        grid-template-columns: 1fr .6fr;
    }

    .smart-search .w-40{
        width: 60%;
    }

    .smart-search .w-60{
        width: 40%;
    }

    .smart-search .photo-grid{
        min-width: 400px;
    }

    .smart-search .column-gap-5x{
        gap: 2rem;
    }

    .property-filter .bed-option{
        display: none;
    } 


}

@media (max-width: 945px){

    .drop-down .drop-down-window{
        left: -100px;
    }
    
    .hero img{
        display: none;
    }

    .home_price{
        display: none;
    }

    .home_status {
        display: none;
    }

    .property-filter .bath-option{
        display: none;
    } 

}


@media (max-width: 855px){


    .split-window{
        grid-template-columns: 1fr .5fr;
    }

    .smart-search article{
        padding-top: 5rem;
        flex-flow: column;
        flex-direction: column-reverse;
    }

    .home-sale article{
        padding-top: 3rem;
        flex-flow: column;
    }

    .home-sale .w-40, .home-sale .w-60{
        width: 100%;
    }

    .home-sale .photo-column{
        margin-top: 5rem;
        height: 300px;
    }

    .smart-search .photo-grid{
        width: 100%;
        max-width: 200px;
        height: 300px;
        min-width: 400px;
        margin-top: 2rem;
    }

    .smart-search .w-40{
        width: 100%;
    }

    .your-home article{
        padding-top: 3rem;
        flex-flow: column;
        flex-direction: column-reverse;
    }

    .your-home .w-40, .your-home .w-60{
        width: 100%;
    }

    .your-home  .content-area{
        margin-bottom: 4rem;
    }


    .padding-top-bottom-12x {
  padding: 5rem 0rem;
}

    .search-result{
        grid-template-columns: 1fr;
    }
    .properties-section{
        width: 100%;
    }
    .the-map{
        
        display: none;
    }

    .mini-map .the-map{
        display: block;
    }

    .prop_info_container{
        grid-template-columns: 1fr;
    }

    .schedule{
        display: none;
    }


    .prop_photos{
        height: 250px;
        grid-template-columns: 1fr;
    }

    .prop_photos figure:nth-child(1){
        border-radius: 5px;
    }

    .prop_photos figure:nth-child(2){
        display: none;
    }

    .prop_photos figure:nth-child(3){
        display: none;
    }

    .single_prop_header ul.share_container{
            gap: 1rem;
        }

        .single_prop_header ul.share_container p{
            display: none;
        }

        .footer_main .contact-info-footer{
            flex-flow: column;
            gap: 1rem;
        }

        .footer_main h2{
            font-size: 1.5rem;
        }
}

@media (max-width: 806px) {
    .padding-top-bottom-11x{
        padding: 4rem 0rem;
    }
    .property-filter .price-option{
        display: none;
    } 
}
@media (max-width: 725px) {

    .quick-search{
        flex-flow: column;
        align-items: center;
        gap: 2rem;
        width: 100%;
        border-radius: 20px;
        padding: 2rem;
    }


    .quick-search ul{

        width: 100%;
        flex-flow: column;
        align-items: center;
        gap: 2rem;

    }



    .quick-search ul li{
        width: 100%;
        max-width: 100% !important;
    }

    .quick-search button, .quick-search a{
        width: 100%;
    }

    .q-dropdown{
            width: 100%;
            max-height: 300px;
            overflow: hidden;
            overflow-y: hidden;
            overflow-y: scroll;
    }

    .q-dropdown h3{
        position: sticky;
        top: 0;
        left: 0;
        background: #f6f6f7;
        z-index: 3;
    }

    .q-dropdown .btn-container{
        position: sticky;
        bottom: 0;
        left: 0;
        background: white;

    }

    .q-dropdown ul{
        gap: 0rem ;
    }

    h1,h2{
        font-size: 2rem;
    }

    .loading-page .loading-container .loading-photo{
        height: 300px;
    }


    .table_style table{
        /* border: 1px solid red; */
        width: 670px;
        
    }

    .table_style .school_options_container
 {
        overflow: hidden;
        overflow-x: scroll;
        width: 500px;
    }

    .property_history ul{
        grid-template-columns: 1fr;
    }
    
}

@media (max-width: 670px) {

    .side-bar-window{
        width: 90%;
        min-width: 90%;
    }
    .split-window{
        grid-template-columns: 1fr;
    }

    .split-window section:nth-child(2){
        display: none;
    }

    .property_list_container{
        grid-template-columns: 1fr;
        width: 100%;
    }
    .property-card{
        max-width: 100%;
    }

}

@media (max-width: 583px) {

    .property-filter .status-option,.property-filter  .home-type-option{
        display: none;
    } 

    .property-filter .field-city-zip{
        width: 100%;
    }

     .home_type{
        display: none;
     }
    .table_style .school_options_container{
        width: 320px;
    }

    .property_filter{
        width: 100%;
    }

    .photo-gallery{
        padding: 0rem;
        grid-template-columns: 1fr;
    }

    .loading-photo{
        height: 250px !important;
    }

    .horizontal-cards{
        grid-template-columns: 1fr 1fr
    }
    
    
}

@media (max-width: 500px) {

    .s-h-btn span{
        display: none;
    }

    .smart-search .photo-grid{
        min-width: 100%;
    }

    .agent_info h2{
        text-align: center;
    }

    .personal_info{

        grid-template-columns: 1fr;
        text-align: center;
    }

    .agent form button{
        width: 100% !important;
    }

    .prop_details{
        grid-template-columns: 1fr;
    }

    form{
        width: 100% !important;
    }

    
}