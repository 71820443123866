body{
  font-family: "Inter";
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--dark-text-color);
  /* color: #202428; */
}

.active-link{
  color: var(--main-color);
}
