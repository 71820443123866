:root{
    --main-color: #f0226f;
    --main-color: rgb(234, 60, 60);
    --secondary-color: #3d98c4;
    --dark-color : #212c51;
    --light-color: #eef6f9;
    --light-silver: #647c9b2b;
    --alt-silver: #92a6bf17;
    --dark-text-color : #2b3644;
    --light-text-color : #232d47;
    --alt-text-color: #63758c;
    --paragraph-text-color : #2b3857;
    --borderRadius: 100px;
    --borderColor: #b6b7b982;
    --lightSilver: #edeeee52;
    --lightShadow : 0px 10px 25px -6px #92a2bf56;
    --shadow : 0 0 10px #a1aaac54;
}

.white-text h1,
.white-text h2,
.white-text h3,
.white-text h4,
.white-text h5,
.white-text h6,
.white-text p
{
    color: white;
}

.main-window{
    min-height: 79.5vh;
}


menu{
    width: 25px;
    height: 14px;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

menu div{
    width: 100%;
    height: 2px;
    background: var(--dark-color);
    position: absolute;
}

menu div:nth-child(2){
    width: 80%;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

menu div:nth-child(3){
    width: 50%;
    bottom: 0;
    right: 0;
}


input:focus{
    outline: 2px solid #43b6ecb0;
}

fieldset{
    border: none;
}

.av-shadow{
    box-shadow: 0 0px 14px -2px #225a6d4f
}

.av-shadow-2{
    box-shadow: 0 0 20px #82bbcc54;
}

.bottom_divider{
    border-bottom: 1px solid var(--light-silver);
    padding-bottom: 2rem;
}

.burger-menu{
    width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 
=================================================
Buttons
=================================================
*/
button{
    border-radius: var(--borderRadius);
    border: none;
    padding: .7rem 1.2rem;
    cursor: pointer;
    transition: .5s;
    font-size: 14px;
    width: 100%;
    font-weight: 600;
}

.main-btn{
    background-color: var(--main-color);
    color: white;
}

.main-btn:hover{
    background: rgb(219, 49, 49);
}


.secondary-btn{
    background-color: transparent;
}

.alt-btn{
    background: rgba(146, 166, 191, 0.09);
    border: 1px solid var(--light-silver);
}


.success_message{
    padding: .5rem 0rem;
    color: #189918;
    font-weight: bold;
}


/* 
=================================================
Container
=================================================
*/

.wide-container{
    width: 100%;
    margin: auto;
    max-width: 2500px;
}
.container{
    width: 98%;
    margin: auto;
    max-width: 2000px;
}

.container-medium{
    width: 85%;
    margin: 0rem auto;
    max-width: 1200px;
}


.input-container{
    position: relative;
}

.server-error{
    color: red;
    margin-bottom: 1.5rem;
    font-size: .9rem;
    background: #ff000012;
    padding: 1rem;
}

.has-value{
    background: #add8e62e !important;
    border: 2px solid var(--secondary-color) !important;
}

.sticky{
    position: sticky;
    top: 0;
    background: white;
    z-index: 12;
}

.stick{
    position: sticky;
    top: 71.4px;
    border-bottom: 1px solid var(--light-silver);
    z-index: 10;
}

.stick_top{
    position: sticky;
    top: 0px;
    border-bottom: 1px solid var(--light-silver);
    z-index: 12;
    background: white;
}

.props-loading{
    position: relative;
}

.props-loading::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: white;
    opacity: .8;
}

/* .stick_top::after{
    content: "";
    width: 100vw;
    height: 100%;
    position: absolute;
    background: white;
    border-bottom: 1px solid var(--light-silver);
} */

/* 
=================================================
Fieldset, Select, Select Options
=================================================
*/

 .select-option{
    border-radius: var(--borderRadius);
    padding: .7rem 1rem;
    border: 1px solid #70879f78;
    width: 100%;
    height: 40px;
}

/* 
=================================================
Columns
=================================================
*/

.display-flex{
    display: flex;
    justify-content: space-between;
}

.flex-1{
    flex: 1;
}

.flex-2{
    flex: 1.2;
}


/* 
=================================================
text alignment
=================================================
*/
.text-left{
    text-align: left;
}

.text-center{
    text-align: center;
}

.text-right{
    text-align: right;
}

.show{
    display: inherit;
}

.hide{
    display: none;
}

/* 
=================================================
WIDTH
=================================================
*/

.with-full{
    width: 100%;
}
/* 
=================================================
GAP
=================================================
*/
.gap-1x{
    gap: .6rem;
}

.gap-2x{
    gap: 1rem;
}

.gap-3x{
    gap: 2rem;
}

.column-gap-5x{
    column-gap: 5rem;
}

.column-gap-7x{
    column-gap: 7rem;
}

.column-gap-8x{
    column-gap: 8rem;
}

.column-gap-10x{
    column-gap: 10rem;
}
/* 
=================================================
WITH
=================================================
*/

.w-40{
    width: 40%;
}

.w-60{
    width: 60%;
}

.w-100{
    width: 100%;
}

.w-90{
    width: 90%;
}

@media (max-width: 700px){

    .w-90{
        width: 98%;
    }
}

/* 
=================================================
Content Position
=================================================
*/
.center-content{
    display: flex;
    justify-content: center;
}

.center{
    margin: auto;
}

.flex-space-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-columns{
    display: flex;
    flex-flow: column;
}
/* 
=================================================
PADDING
=================================================
*/
.padding-top-bottom-small{
    padding: .6rem 0rem;
}

.padding-top-bottom{
    padding: 1rem 0rem;
}

.padding-top-bottom-11x{
    padding: 8rem 0rem;
}

.padding-top-bottom-12x{
    padding: 12rem 0rem;
}

.padding-top-bottom-13x{
    padding: 13rem 0rem;
}

.padding-top-bottom-8x{
    padding: 8rem 0rem;
}

.padding-top-bottom-2x{
    padding: 3rem 0rem;
}

.no-padding{
    padding: 0rem;
}

.padding-top{
 padding-top: 1rem;
}

.padding-top-2x{
    padding-top: 2rem;
   }

.padding-bottom{
    padding-bottom: 1rem;
}

.padding-bottom-2x{
    padding-bottom: 2rem;
}

.padding-bottom-3x{
    padding-bottom: 3rem;
}

.no-padding-bottom{
    padding-bottom: 0px;
}
/* 
=================================================
MARGIN 
=================================================
*/

.margin-top-1x{
    margin-top: 1rem;
}

.margin-top-2x{
    margin-top: 2rem;
}

.margin-bottom-3x{
    margin-bottom: 3rem;
}

.margin-bottom-4x{
    margin-bottom: 4rem;
}


/* 
=================================================
BACKGROUND
=================================================
*/

.light-bk{
    background: #add8e62e;
}

@media(min-width: 900px){

    .hideDesktop{
        display: none;
    }
    
}

@media(max-width: 900px){

    .container{
        width: 90%;
    }

    .hideMobile{
        display: none !important;
    }
    
}

.nav-list{
    margin-left: 4rem;
}

.nav-list li{
    padding: .5rem .6rem;
    font-weight: 500;
  }
  
  .nav-list li:hover{
      color : var(--main-color)
  }

  .nav-contact-info{
    margin-right: 3rem;
  }

  .nav-contact-info li{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .8rem;
    
  }

  .nav-contact-info li i{
    color: var(--alt-text-color);
  }

  .nav-contact-info li p{
    color: var(--alt-text-color);
    font-size: .9rem;
  }


.top-nav{
    width: 100%;
    top: 0;
    z-index: 12;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem;
}

.top-nav .logo img{
    max-width: 150px;
}

.border-bottom{
    border-bottom: 1px solid var(--light-silver);
}

.user-nav-likes{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .3rem;
    padding: .6rem .8rem;
    font-size: .9rem;
    border: none;
    border-radius: 100px;
    background: #f7f7f7;
    cursor: pointer;
    width: max-content;
    position: relative;
}

.user-nav-likes span{
    font-weight: 500;
}

.user-nav-bell{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--light-silver);
    position: relative;
    border-radius: 10px;
    background: #92a6bf17;
    width: max-content;
    font-size: 1rem;

}

.like_count{
    position: absolute;
    background: var(--main-color);;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: white;
    right: -8px;
    top: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .7rem;
    font-weight: 600;

}

.user-nav-likes p{
    color: var(--dark-text-color);
    font-weight: 400;
}

.user-nav-info{
    display: flex;
    align-items: center;
    gap: 2rem;
}


.user-nav-account{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: .5rem .5rem;
    border: none;
    font-size: 1rem;
    width: 33px;
    height: 33px;
    background: white;
    color: white;
    cursor: pointer;
    border: 1px solid var(--light-silver);
}

.user-nav-account i{
   color: var(--alt-text-color);;
}

.nav-list-alt li{
    color: white;
}

.account-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .8rem;
    cursor: pointer;
}

.user-greeting{
    font-weight: 500;
}

.account-drop-down-window{
    top: 120%;
    padding: 1rem;
    z-index: 100;
    border-radius: 10px;
    box-shadow: 0px 0px 30px -13px #708895;
    border: 1px solid var(--alt-silver);
    width: max-content;
    min-width: 100%;
    padding: 1rem .5rem;
}

.account-drop-down-window ul{
    display: flex;
    flex-flow: column;
}

.account-drop-down-window ul li {
 display: flex;
 align-items: center;
 gap: .5rem;
 cursor: pointer;
 padding: .7rem .5rem;
 border-radius: 10px;
}

.account-drop-down-window ul li:hover{
    background: var(--alt-silver);
}

.delete-acc {
    color: #e12323;
}

.delete-acc  i{
    color: #e12323;
}


.search-result{
    display: grid;
    grid-template-columns: 1fr .8fr;
    margin-top: 69px !important;
}

.property-filter{
    padding: 1rem;
    display: flex;
    gap: .5rem;
}

.property-filter fieldset input{
    border-radius: var(--borderRadius);
    padding: .7rem 1rem;
    border: 1px solid #70879f78;
    width: 100%;
    height: 50px;
}

.property-filter .field-city-zip{
    width: 25%;
}

.property-filter .field-city-zip input{
    padding-left: 2rem;
    height: 46px;
}

.property-filter fieldset{
    margin: 0;
}

.field-city-zip {
    position: relative;
}

.field-city-zip input{
    padding-left: 2rem;
    height: 46px;
}

.field-city-zip .fa-location-dot{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    margin: auto;
    height: max-content;
}

.field-city-zip .fa-magnifying-glass{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
    height: max-content;
}

.search-result .property-filter{
    grid-column: 1 / 3;
}

.filter_container{
    background: white;
}

.top-nav-stick{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 15;
}

.no-result{
padding: 0.8rem;
font-weight: normal;
font-size: .9rem;
color: rgb(247, 0, 0);
border: 1px solid red;
width: max-content;
border-radius: var(--borderRadius);
margin: auto;
  margin-bottom: auto;
margin-bottom: auto;
margin-bottom: 1rem;
position: absolute;
left: 0;
right: 0;
top: 120%;
background: white;
}

.drop-down{
    position: relative;
}

.drop-down .drop-down-window{
    position: absolute;
    background: white;
}

.drop-down.open .drop-down-window{
    display: inherit;
}

.drop-down.close .drop-down-window{
    display: none;
}
.filter-dropdown .drop-down-button{
    background: white;
    border: 1px solid #70879f78;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .3rem;
    height: 46px;
}

.filter-dropdown .drop-down-button i{
    margin-left: .3rem;
}

.drop-down-input {
    position: relative;
}

.drop-down-input input{
    cursor: pointer;
    max-width: 130px;
}

.drop-down-input i {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  margin: auto;
  height: max-content;
}

.input-filter-dropdown{
    padding: 1rem;
    display: flex;
    gap: 1rem;
}

.input-drop-down-window{
    position: absolute;
    left: 0;
    top: 110%;
    border-radius: 10px;
    box-shadow: var(--shadow);
    width: max-content;
    background: white;
    border: 1px solid rgb(228, 227, 227);
    z-index: 1;
    min-width: 230px;

}

.filter-dropdown .filter-dropdown-window{
    position: absolute;
    left: 0;
    top: 110%;
    border-radius: 10px;
    box-shadow: var(--shadow);
    width: max-content;
    background: white;
    border: 1px solid rgb(228, 227, 227);
    z-index: 1;
    min-width: 230px;
}

.filter-dropdown-window header{
    background: var(--alt-silver) ;
    padding: 1rem;
}

.filter-dropdown-window header h3{
    color: var(--alt-text-color);
  font-weight: 550;
  font-size: 14px;
}

.filter-dropdown-window ul{
    padding: 1rem;
    padding-bottom: .3rem;
}

.filter-dropdown-window ul li{
    padding: .5rem 0rem;
}

.filter-dropdown-window ul li i{
    margin-right: .8rem;
}

.filter-drop-down-footer{
    padding: 1rem;
    padding-top: .7rem;
}

.home-type-option{
    min-width: 155px;
}

.status-option{
    min-width: 185px;
}

.price-option{
    min-width: 135px;
}


.drop-down-min-height{
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
}

.bed-option{
    min-width: 130px;
}

.bed-option-button{
    min-width: 105px;
}

.bed-count-option{
    min-width: 130px !important;
}

.the-map{
    height: 100%;
}

.no_map{
    position: relative;
    height: 87vh;
}

.no_map::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: #f8f9f9;
    animation : loading-map .6s linear infinite alternate;
}

@keyframes loading-map {

    0%{
        background: #f8f8f88c;
    }
    100%{
        background: #f4f4f4;
    }
    
}


.sticky_map{
    position: sticky !important;
    top: 7.8rem;
    left: 0;
    height: calc(100vh - 151px);
    flex: 1 1;
    min-height: 300px;
}

.split-window{
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;
}

.loading-overlay{
    position: relative;
}

.loading-overlay::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fbfbfbcb;
    z-index: 2;
}
