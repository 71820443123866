/* 
=================================================
TAGS
=================================================
*/

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

li{
    list-style: none;
    cursor: pointer;
}

a{
    text-decoration: none;
    color: inherit;
}

p{
    color: var(--alt-text-color);
    max-width: 550px;
    margin-bottom: .6rem;
    line-height: 1.8;
}

h1,h2,h3,h4,h5{
    line-height: 1.4;
}

h1{
    font-size: 3rem;
    color: var(--dark-text-color);
    margin-bottom: 1rem;
}

h2{
    font-size: 2.5rem;
    margin-bottom: 1.2rem;
}

h3{
    font-weight: 600;
    color: var(--dark-text-color);
    font-size: 1rem;
}

h4{
    font-weight: 600;
}

h5{
    color: var(--alt-text-color);
    font-weight: 500;
    font-size: 1.2rem;
}

article button{
    width: max-content;
}

section img{
    width: 100%;
}