.slide-out-left{
    animation: slideOutLeft .2s forwards;
   }

   @keyframes slideOutLeft {

        from{
            right: -500px;
        }
        to{
            right: 0;
        }
    
   }

.slide-in-right{
    animation: slideInRight .2s forwards;
   }

   @keyframes slideInRight {

        from{
            right: 0;
        }
        to{
            right: -800px;
        }
    
   }

.fade-in{
    animation: fadeIn .2s forwards;
   }

   @keyframes fadeIn {

        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    
   }

   .fade-out{
    animation: fadeOut .2s forwards;
   }

   @keyframes fadeOut {

        from{
            opacity: 1;
        }
        to{
            opacity: 0;
        }
    
   }